<template>
  <div>
    <!--导航栏--->
    <div><van-nav-bar title="收货地址" left-arrow @click-left="close" /></div>
    <!--导航栏--->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        :immediate-check="false"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        style="padding-bottom: 50px"
        v-show="!isEmpty"
      >
        <van-swipe-cell
          v-for="(item, index) in pageData"
          :key="item.userId + index"
        >
          <van-cell is-link center @click="selectAddress(item)">
            <!-- 使用 title 插槽来自定义标题 -->
            <template #title>
              <span class="title">{{ item.userName }}</span>
              <span class="title" style="margin-left: 6px">{{
                item.telNum
              }}</span>
              <van-tag
                color="#FF0038"
                text-color="#ffffff"
                v-show="item.isDefault == 1"
                style="margin-left: 8px"
                >默认</van-tag
              >
              <!-- <van-tag color="#3E85FF" text-color="#ffffff">家</van-tag> -->
            </template>
            <!-- 使用 label 插槽来自定义描述 -->
            <template #label>
              <span class="label">
                <!-- {{ item.detailInfo }} -->
                <span style="margin-right: 15px">{{item.provinceName + item.cityName + item.countyName + item.detailInfo}}</span>
              </span>
            </template>
            <template #right-icon>
              <img
                src="img/shoppingAddress/edit@2x.png"
                @click.stop="
                  $router.push({
                    path: '/shopAddAddress',
                    query: { address: JSON.stringify(item) },
                  })
                "
                style="width: 24px; height: 24px"
              />
            </template>
          </van-cell>

          <template #right>
            <van-button
              square
              text="删除"
              type="danger"
              style="height: 100%"
              @click="deleteAddress(item)"
            />
          </template>
        </van-swipe-cell>
      </van-list>
    </van-pull-refresh>

    <!-- 缺省 -->
    <div class="noAddress" v-show="isEmpty">
      <img
        src="img/shoppingAddress/noAddress@2x.png"
        style="width: 181px; height: 200px"
      />
    </div>

    <!-- 底部 -->
    <div @click="$router.push('/shopAddAddress')">
      <van-goods-action>
        <van-goods-action-button type="primary" text="新建收货地址" />
      </van-goods-action>
    </div>
    <!-- 底部 -->
  </div>
</template>

<script>
import { getPage, deleteAddress } from "@/api/shoppingAddress/shoppingAddress";
import { Toast } from "vant";

export default {
  data() {
    return {
      pageData: [],
      loading: true,
      finished: false,
      error: false,
      refreshing: false,
      total: 0,
      current: 1,
      size: 10,
      isEmpty: false,
      isFirst: false,
    };
  },
  created() {
    if (this.$route.query.isFirst) {
      this.isFirst = this.$route.query.isFirst;
    }
    // Toast( localStorage.getItem('setSession'))
    if (this.$route.query.session) {
      this.$store.commit("setSession", this.$route.query.session);
    }
  },
  mounted() {
    this.getPage();
  },
  methods: {
    onLoad() {
      this.getPage();
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.current = 1;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.onLoad();
    },
    getPage() {
      getPage({ current: this.current, size: this.size }).then((res) => {
        if (res.data.code === 0) {
          //  Toast(res.data.message)
          //  console.log(11111113333);
          this.total = res.data.data.total;
          if (this.refreshing) {
            this.pageData = [];
            this.refreshing = false;
          }
          this.pageData = this.pageData.concat(res.data.data.records);
          this.loading = false;

          if (this.pageData.length >= this.total) {
            this.finished = true;
          }
          console.log(this.finished);
          this.current++;
          this.isEmpty = this.pageData.length == 0;
        } else {
          // console.log(1111111222);
          // Toast(res.data.message)
          if (this.refreshing) {
            this.pageData = [];
            this.refreshing = false;
          }
          this.error = true;
          this.loading = false;
        }
      });
    },
    deleteAddress(item) {
      deleteAddress(item.id).then((res) => {
        if (res.data.code === 0) {
          Toast("删除成功");
          this.dialogDelete = false;
          this.refreshing = true;
          this.onRefresh();
        } else {
          Toast("删除失败");
        }
      });
    },
    // 选择地址
    selectAddress(item) {
      sessionStorage.setItem("address", JSON.stringify(item));
      this.$route.params.address = item;
      //调用router回退页面
      this.$router.back(-1);
    },
    // 页面返回
    close() {
      if (this.$route.query.first == 0) {
        console.log(111);
        this.$router.back(-1);
      } else {
        console.log(222);
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },
  },
  watch: {},
  computed: {},
};
</script>

<style lang="less" scoped>
/deep/.van-button--primary {
  background-color: #f52556;
}
.noAddress {
  width: 100%;
  height: 80%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}

.title {
  color: #333333;
  font-size: 14px;
  font-weight: 500;
}

.label {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
}
</style>